.contact{
    height: 100%;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 10% 15% 10px 75%;
    grid-gap: 10px;

}

.contactInfo{
    grid-column: 2/3;
    grid-row: 4;
    display: flex;
    flex-direction: column;
}
.contactInfo h2{
    display: inline-block;
}

.basicContactInfo{
    display: inline-block;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-bottom: 3%; 
}

.basicContactInfo div{
    width: 100%;
    height: 50%;
    display: flex;
    padding: 50px none;
    justify-content: center;
    color: aliceblue;
}
.basicContactInfo div img{
    margin-right: 1%;
}
.basicContactInfo div h2{
    font-size: 2vw;
}

.basicContactInfo div a{
    color: aliceblue;
    transition: all .2s ease-in-out;
    transition: color 0.5s ease;
}
.basicContactInfo div a:hover{
    transform: scale(1.1);
    color: rgb(46, 136, 214);
}

.mediaLinks{
    display: flex;
    justify-content: center;

}
.mediaLinks img{
    height: 5vw;  
    margin: 0px 1vw;  
    min-height: 50px;
    transition: all .2s ease-in-out;
}
.mediaLinks img:hover{
    opacity: 0.5;
    transform: scale(1.1);
}

.contactTitle h1{
    font-size: 10vh;
    color: aliceblue;
}

.contactTitle{
    grid-column:2/3 ;
    grid-row: 2;
    border-bottom: 5px solid aliceblue;
    height: fit-content;
}

.contactTitle h1{
    font-size: 13vw;
}
.phonimage{
    height: 50px;
    
    
}

@media only screen and (max-width: 1100px) {
    .basicContactInfo div h2{
        font-size: 20px;
    }
}

@media only screen and (max-width:480px) {
    .contact{
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 80% 1fr;
        grid-template-rows: 10% 15% 10px 75%;
        grid-gap: 10px;
    }
    .phonimage{
        height: 25px;
    }
    .contactTitle h1{
        font-size: 9vw ;
        text-align: center;  
    }
}

@media only screen and (min-width: 540px) {
    .contactTitle h1{
        font-size: 9vh ;
    }
}

@media only screen and (max-width: 540px) {
    .contactTitle h1{
        font-size: 9vw;
        text-align: center;
    }
}
