
.aboutMe{
    min-height: 100%;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 15% 35% 10%  10% auto;
    color: aliceblue;
}

.introA{
    grid-column: 2/3;
    grid-row: 1/2;
    height: auto;
}


.pictureBackground{
    background-color: brown;
    display: inline;
    width: auto;
    height: fit-content;

}

.pictureBackground img{
    height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: 50%;
    scroll-behavior: smooth;    
}

.introB{
    grid-column: 2/3;
    grid-row: 2/3;
}
.introC{
    grid-column:2/3;
    grid-row: 5/6;
    background-color: aliceblue;
    display: flex;
    overflow: scroll;
    flex-wrap: wrap;  
    overflow-x: hidden; 
}

.me{
    grid-column: 2/3;
    grid-row: 2/3;
    margin: 0px auto;
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 0;
    overflow: hidden;
    text-align: center;
    /* background-color: rgba(255, 255, 255, 0.541); */
}
.p{
    /* height: 135px; */
    background-color: rgb(240, 240, 240);
    height: 50%;
    
}

.cardheader {
    background: url("https://images.freecreatives.com/wp-content/uploads/2016/03/Colorful-Material-Design-Background.jpg");
    background-size: cover;
    /* background-color: rgba(255, 255, 255, 0) ; */
    opacity: 0;
    height: 20%;
    /* width: 25%; */
}

.avatar {
    position: relative;
    top: -50px;
    margin-bottom: -50px;
}

.avatar img {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 5px solid rgba(255,255,255,0.5);
    background-color: rgb(191, 227, 231);
    display: block;
    margin: 0px auto;
}

.info {
    padding: 4px 8px 10px;
    
}
.info .title {
    margin-bottom: 3vh;
    font-size: 5vh;
    line-height: 1;
    color: #262626;
    vertical-align: middle;
}

.info .description {
    margin-bottom: 4px;
    font-size: 3vh;
    line-height: 1;
    color: #262626;
    vertical-align: middle;
    font-size: 24px;
}

.z{
    color: #262626 !important;
    border: 1px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-width: 222px;
    overflow-y: hidden  !important; 
    height: 20%;

}
.z h3{
    text-align: center;
    margin-left: 1%;
}
.z img{
    height: 40px;
}

.aboutMeTitle{
    grid-column: 2/3;
    grid-row: 1/2;
    height: fit-content;
    border-bottom: 5px solid aliceblue;

}

.aboutMeTitle h1{
    font-size: 9vh ;
}

.languageTitle{
    font-size: 9vh;
    grid-column: 2/3;
    grid-row: 4/5;
}
.languageTitle h2{
    font-size: 5vh;
}
@media only screen and (max-width: 739px) {
    .z{
        color: #262626 !important;
        border: 1px solid grey;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 222px;
        overflow: scroll;
        overflow-x: hidden;
        height: 75px;
    }
    .z h3{
        text-align: center;
        margin-left: 1%;
    }
}

@media only screen and (max-width: 480px) {
    .aboutMe{
        min-height: 100%;
        display: grid;
        grid-template-columns: 10% 80% 10%;
        grid-template-rows: auto 25% 5%  10% auto;
        color: aliceblue;
    }
  }

@media only screen and (max-width: 540px) {
    .aboutMeTitle h1{
        font-size: 9vw ;
        text-align: center;
    }
  }