.indiviualProjects{
    border: 5px solid aliceblue;
    margin: 5px auto;
    width: 30.3333%;
    height: fit-content;
    min-width: 341px;
    background-color: aliceblue;
    border-radius: 5%;

    display: inline-block;
}

.indiviualProjects img{
    width: 100%;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
}

.links{
    display: flex;
    justify-content: space-around;
}

.links div{
    display: inline;
    border: 3px solid black;
    border-radius: 10%;
    width: 80px;
}
.links div a p{
    font-size: 15px;
    font-weight: bold;
    margin: 0px;
    text-align: center;
    color: black;
}
.githubVer{
    background-color: #fff;
}
.liveVer{
    background-color: #fff;
}
.isDisabled {
    color: grey;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    
  }
@media only screen and (max-width: 596px) {
    .indiviualProjects{
        width: 100%;
        min-width: 0px;
        background-color: aliceblue;
        border-radius: 5%;
        height: fit-content;
    }

}

